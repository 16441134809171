import React from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import isNil from 'lodash/isNil'

import { withAppConfig } from 'pmt-modules/appConfig'
import { EventManager } from 'pmt-modules/event'
import { showDietaryInfoDialog, withCatalog } from 'pmt-modules/catalog'
import { redirectTo } from 'pmt-modules/routing'
import { SuggestionActionType } from 'pmt-modules/suggestion'
import SelectedProductContainer from 'pmt-modules/orderProduct/components/SelectedProductContainer'
import { selectOptionValue, unselectOptionValue, setReclaimLater } from 'pmt-modules/orderProduct'
import {
  addOrderProductToMenu,
  updateOrderProductToMenu,
  getOrderMenu,
} from 'pmt-modules/orderMenu'
import {
  hideProductDialog,
  SelectedCategoryContainer,
  getOrderProperties,
  saveAlcoholApproval,
  getAppConfigFrontSettings,
} from 'pmt-modules/orderPlugin'
import {
  addOrderMenuToCart,
  addOrderProductToCart,
  updateOrderProductOnCart,
  getSuspendedDatas,
  setSuspendedDatas,
} from 'pmt-modules/cart'
import withRestaurant from 'pmt-modules/restaurant/components/withRestaurant'

import View from '../components/View'

/**
 * @specs N/A
 */
@withAppConfig
@withRestaurant
@withCatalog
class ProductDialog extends React.Component {
  addSuspendedItem = () => {
    const {
      cartSuspendedDatas,
      addOrderProductToCart,
      addOrderMenuToCart,
      setSuspendedDatas,
    } = this.props

    // we check if we have suspended item to be added to cart when the user quit the product
    // we need to be in replace mode because the compose mode is already added
    if (
      !isNil(cartSuspendedDatas) &&
      cartSuspendedDatas.suspendedActionType === SuggestionActionType.REPLACE
    ) {
      if (cartSuspendedDatas.suspendedItem.isProduct) {
        addOrderProductToCart(cartSuspendedDatas.suspendedItem, { skipSuggestion: true })
      } else if (cartSuspendedDatas.suspendedItem.isMenu) {
        addOrderMenuToCart(cartSuspendedDatas.suspendedItem, { skipSuggestion: true })
      }
      setSuspendedDatas(null, null)
    }
  }

  handleSelectOptionValue = (option, value) => {
    this.props.selectOptionValue(option, value)
  }

  handleUnselectOptionValue = (option, value) => {
    this.props.unselectOptionValue(option, value)
  }

  handleOnSubmit = (orderProduct, category) => {
    const {
      viewOptions,
      updateOrderProductOnCart,
      updateOrderProductToMenu,
      addOrderProductToCart,
      addOrderProductToMenu,
      orderProperties,
      restaurant,
      orderMenu,
      hideProductDialog,
      cartSuspendedDatas,
      setSuspendedDatas,
    } = this.props

    if (orderProduct.isValid) {
      if (!orderProduct.hasSuggestion) {
        EventManager.dispatch(EventManager.Events.ON_CATALOG_ITEM_ADD_PRODUCT, {
          category,
          dueDate: orderProperties.dueDate,
          item: orderProduct,
          restaurant: restaurant,
        })
      }

      if (viewOptions.isMenuMode === true) {
        // menu mode
        if (viewOptions.isEditMode === true) {
          updateOrderProductToMenu(orderProduct, viewOptions.partId, viewOptions.partCategoryId)
        } else {
          EventManager.dispatch(EventManager.Events.ON_CATALOG_MENU_ADD_PRODUCT, {
            category,
            dueDate: orderProperties.dueDate,
            item: orderProduct,
            menu: orderMenu,
            restaurant: restaurant,
          })

          addOrderProductToMenu(orderProduct, viewOptions.partId, viewOptions.partCategoryId, {
            skipSuggestion: viewOptions.skipSuggestion ? viewOptions.skipSuggestion : true,
          })
        }
      } else {
        // product mode
        if (viewOptions.isEditMode === true) {
          updateOrderProductOnCart(orderProduct)
        } else {
          addOrderProductToCart(orderProduct, {
            skipSuggestion: viewOptions.skipSuggestion ? viewOptions.skipSuggestion : false,
          })
        }

        // if the user submit the product to add
        // we reset suspended item in cart
        if (!isNil(cartSuspendedDatas)) {
          setSuspendedDatas(null, null)
        }
      }

      hideProductDialog()
    }
  }

  setReclaimLater = isChecked => {
    this.props.setReclaimLater(isChecked)
  }

  render() {
    const {
      orderAppConfig,
      appConfigPreset,
      catalog,
      viewOptions,
      hideProductDialog,
      showDietaryInfoDialog,
      orderProperties,
      saveAlcoholApproval,
      frontSettings,
    } = this.props
    // viewOptions:
    // - isMenuMode {boolean}
    // - isEditMode {boolean}

    const { isMenuMode, isEditMode } = viewOptions

    return (
      <SelectedCategoryContainer catalog={catalog}>
        {({ selectedCategory }) => (
          <SelectedProductContainer catalog={catalog} isMenuMode={isMenuMode}>
            {({ orderProduct }) =>
              orderProduct ? (
                <View
                  appConfigPreset={appConfigPreset}
                  orderAppConfig={orderAppConfig}
                  orderProduct={orderProduct}
                  isMenuMode={isMenuMode}
                  isEditMode={isEditMode}
                  onSelectOptionValue={this.handleSelectOptionValue}
                  onUnselectOptionValue={this.handleUnselectOptionValue}
                  onSubmit={() => this.handleOnSubmit(orderProduct, selectedCategory)}
                  onClose={() => {
                    if (!isMenuMode) {
                      this.addSuspendedItem()
                    }
                    hideProductDialog()
                  }}
                  showDietaryInfoDialog={() => showDietaryInfoDialog(orderProduct)}
                  setReclaimLater={this.setReclaimLater}
                  orderProperties={orderProperties}
                  saveAlcoholApproval={saveAlcoholApproval}
                  frontSettings={frontSettings}
                />
              ) : (
                <div />
              )
            }
          </SelectedProductContainer>
        )}
      </SelectedCategoryContainer>
    )
  }
}

// Dialog root properties
ProductDialog.DialogRootProps = {
  // we want the dialog to quit when we click on the backdrop
  disableBackdropClick: false,
}

ProductDialog.DialogStyles = theme => ({
  paper: {
    width: '100%',
    // fix weird bug on Safari
    zIndex: 100,
    // -- for IE
    '-ms-overflow-style': '-ms-autohiding-scrollbar',
    [theme.breakpoints.down('sm')]: {
      height: '100%',
      maxHeight: '100%',
      margin: 0,
      borderRadius: 0,
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: 760,
      // -- for IE
      width: '100%',
    },
  },
})

const mapStateToProps = (state, props) => ({
  orderProperties: getOrderProperties(state),
  cartSuspendedDatas: getSuspendedDatas(state),
  orderMenu: getOrderMenu(state),
  frontSettings: getAppConfigFrontSettings(state),
})

export default compose(
  connect(mapStateToProps, {
    hideProductDialog,
    selectOptionValue,
    unselectOptionValue,
    addOrderProductToCart,
    addOrderProductToMenu,
    addOrderMenuToCart,
    updateOrderProductToMenu,
    updateOrderProductOnCart,
    setSuspendedDatas,
    redirectTo,
    showDietaryInfoDialog,
    setReclaimLater,
    saveAlcoholApproval,
  })
)(ProductDialog)
