import React from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import isNil from 'lodash/isNil'

import { EventManager, SendEventContainer } from 'pmt-modules/event'
import { getRoute, redirectTo } from 'pmt-modules/routing'
import { withAppConfig } from 'pmt-modules/appConfig'
import { showDietaryInfoDialog, withCatalog } from 'pmt-modules/catalog'
import withRestaurant from 'pmt-modules/restaurant/components/withRestaurant'
import { SuggestionActionType, hideSuggestionDialog } from 'pmt-modules/suggestion'
import SelectedProductContainerById from 'pmt-modules/orderProduct/components/SelectedProductContainerById'
import {
  withCategory,
  SelectedCategoryContainer,
  OrderPluginUrlCheckerContainer,
  goBackToStoreLocator,
  redirectToCatalogPage,
  redirectToCart,
  withOrderProperties,
  getAppConfigFrontSettings,
} from 'pmt-modules/orderPlugin'
import { prepareForFetchingUpsells } from 'pmt-modules/upselling'
import {
  getViewOptions,
  selectOptionValue,
  unselectOptionValue,
  setReclaimLater,
} from 'pmt-modules/orderProduct'
import { displayDeleteCartDialog } from 'pmt-modules/dialog'
import {
  getItemListFromCart,
  addOrderMenuToCart,
  addOrderProductToCart,
  updateOrderProductOnCart,
  getSuspendedDatas,
  setSuspendedDatas,
} from 'pmt-modules/cart'

import withWidth, { isWidthUp, isWidthDown } from 'pmt-ui/utils/withWidth'
import withScrollToTop from 'pmt-ui/Layout/withScrollToTop'
import { saveAlcoholApproval } from 'pmt-modules/orderPlugin'

import OrderPage from '../../components/OrderPage'
import View from './View'
import { getUpsellingData } from 'pmt-modules/upselling'

/**
 * @specs N/A
 */
@withAppConfig
@withOrderProperties
@withRestaurant
@withCatalog
@withCategory
@withScrollToTop()
class CatalogPage extends React.Component {
  constructor(props) {
    super(props)

    this.addSuspendedItem()
  }

  addSuspendedItem = () => {
    const {
      cartSuspendedDatas,
      addOrderProductToCart,
      addOrderMenuToCart,
      setSuspendedDatas,
    } = this.props

    // we check if we have suspended item to be added to cart
    // we need to be in replace mode because the compose mode is already added
    if (
      !isNil(cartSuspendedDatas) &&
      cartSuspendedDatas.suspendedActionType === SuggestionActionType.REPLACE
    ) {
      if (cartSuspendedDatas.suspendedItem.isProduct) {
        addOrderProductToCart(cartSuspendedDatas.suspendedItem, { skipSuggestion: true })
      } else if (cartSuspendedDatas.suspendedItem.isMenu) {
        addOrderMenuToCart(cartSuspendedDatas.suspendedItem, { skipSuggestion: true })
      }
      setSuspendedDatas(null, null)
    }
  }

  handleSelectOptionValue = (option, value) => {
    this.props.selectOptionValue(option, value)
  }

  handleUnselectOptionValue = (option, value) => {
    this.props.unselectOptionValue(option, value)
  }

  hasRedirectUriDefined = () => this.props.frontSettings && this.props.frontSettings.redirectUri

  handleRedirectToCart = () => {
    if (!this.props.orderProperties.hasAlreadyDisplayedUpselling) {
      // once the upsells are fetched, if there are some, it will trigger the display of the UpsellingDialog
      this.props.prepareForFetchingUpsells()
    }

    EventManager.dispatch(EventManager.Events.ON_CATALOG_CART_SUBMIT, {
      items: this.props.itemListFromCart,
      restaurant: this.props.restaurant,
    })

    this.props.redirectTo(getRoute('ORDER__CART'))
  }

  handleRedirectToStoreLocator = () => {
    if (this.hasRedirectUriDefined()) {
      window.location.href = this.props.frontSettings.redirectUri
    } else {
      this.props.itemListFromCart.length > 0
        ? this.props.displayDeleteCartDialog()
        : this.props.goBackToStoreLocator()
    }
  }

  handleOnSubmit = (orderProduct, category) => {
    const {
      viewOptions,
      updateOrderProductOnCart,
      addOrderProductToCart,
      orderProperties,
      restaurant,
      cartSuspendedDatas,
      setSuspendedDatas,
    } = this.props

    if (orderProduct.isValid) {
      if (!orderProduct.hasSuggestion) {
        EventManager.dispatch(EventManager.Events.ON_CATALOG_ITEM_ADD_PRODUCT, {
          category,
          dueDate: orderProperties.dueDate,
          item: orderProduct,
          restaurant: restaurant,
        })
      }

      // product mode
      if (viewOptions.isEditMode) {
        updateOrderProductOnCart(orderProduct)
        this.handleRedirectToPreviousPage()
      } else {
        // if the user submit the product to add
        // we reset suspended item in cart
        if (!isNil(cartSuspendedDatas)) {
          setSuspendedDatas(null, null)
        }

        addOrderProductToCart(orderProduct, {
          skipSuggestion: viewOptions.skipSuggestion ? viewOptions.skipSuggestion : false,
        })
        this.handleRedirectToCatalogPage()
      }
    }
  }

  handleRedirectToPreviousPage = () => {
    if (this.props.isFromCart) {
      this.handleRedirectToCartPage()
    } else {
      this.handleRedirectToCatalogPage()
    }
  }

  handleRedirectToCatalogPage = () => {
    this.props.redirectToCatalogPage(this.props.restaurant.id)
  }

  handleRedirectToCartPage = () => {
    this.props.redirectToCart()
  }

  setReclaimLater = isChecked => {
    this.props.setReclaimLater(isChecked)
  }

  render() {
    const {
      isFetchingCatalog,
      isFetchingRestaurant,
      catalog,
      upsellingData,
      orderProperties,
      restaurant,
      location,
      params,
      width,
      viewOptions,
      redirectTo,
      showDietaryInfoDialog,
      orderAppConfig,
      appConfigPreset,
      saveAlcoholApproval,
      frontSettings,
    } = this.props

    return (
      <SelectedCategoryContainer catalog={catalog}>
        {({ selectedCategory }) => (
          <OrderPage
            headerProps={{
              // hide the header when there is a selected children category, since we display
              // the category header
              displayHeader: isWidthUp('md', width),
              displayRestaurantName: true,
              displayTitle: isWidthUp('md', width),
              displayOrderMode: true,
              displayAddress: true,
              changeButton: isWidthUp('md', width) && this.handleRedirectToStoreLocator,
              displayDueDate: isWidthDown('sm', width),
              displayTableNumber: isWidthDown('sm', width),
              displayUser: true,
            }}
            isWhiteBackground={isWidthDown('sm', width)}
            orderProperties={orderProperties}
            restaurant={restaurant}
            location={location}
          >
            <OrderPluginUrlCheckerContainer
              location={location}
              params={params}
              orderProperties={orderProperties}
              verifyMode
              verifyRestaurantId
              verifyTableNumber
              verifyFrom
            >
              {({ isFetchingAppConfig, appConfig }) => (
                <SelectedProductContainerById
                  catalog={catalog}
                  upsellingData={upsellingData}
                  selectedCategory={selectedCategory}
                  productId={params.productId}
                >
                  {({ orderProduct }) => (
                    <SendEventContainer
                      send={
                        orderProperties &&
                        !isNil(orderProperties.restaurant) &&
                        !isNil(orderProperties.dueDate)
                      }
                      event={EventManager.Events.ON_PAGE_LOADED}
                      eventProps={() => ({
                        restaurant: orderProperties.restaurant,
                        dueDate: orderProperties.dueDate,
                      })}
                    >
                      <View
                        appConfigPreset={appConfigPreset}
                        orderAppConfig={orderAppConfig}
                        isFetchingRestaurant={isFetchingRestaurant}
                        restaurant={restaurant}
                        isFetchingCatalog={isFetchingCatalog}
                        catalog={catalog}
                        orderProduct={orderProduct}
                        orderProperties={orderProperties}
                        isMenuMode={false}
                        isEditMode={!isNil(viewOptions) ? viewOptions.isEditMode : false}
                        route={this.props.route}
                        selectedCategory={selectedCategory}
                        hideSideInformations={this.hideSideInformations}
                        showSideInformations={this.showSideInformations}
                        onRedirectToCart={this.handleRedirectToCart}
                        onSelectOptionValue={this.handleSelectOptionValue}
                        onUnselectOptionValue={this.handleUnselectOptionValue}
                        setReclaimLater={this.setReclaimLater}
                        handleRedirectToStoreLocator={this.handleRedirectToStoreLocator}
                        onSubmit={() => this.handleOnSubmit(orderProduct, selectedCategory)}
                        onClose={() => {
                          redirectTo(getRoute('ORDER__CATEGORY'), {
                            restaurantId: restaurant.id,
                            categoryId: selectedCategory.id,
                          })
                        }}
                        showDietaryInfoDialog={() => showDietaryInfoDialog(orderProduct)}
                        saveAlcoholApproval={saveAlcoholApproval}
                        frontSettings={frontSettings}
                      />
                    </SendEventContainer>
                  )}
                </SelectedProductContainerById>
              )}
            </OrderPluginUrlCheckerContainer>
          </OrderPage>
        )}
      </SelectedCategoryContainer>
    )
  }
}

const mapStateToProps = (state, props) => {
  const viewOptions = getViewOptions(state)

  return {
    itemListFromCart: getItemListFromCart(state),
    cartSuspendedDatas: getSuspendedDatas(state),
    viewOptions: getViewOptions(state),
    isFromCart:
      viewOptions &&
      viewOptions.fromRoute &&
      viewOptions.fromRoute.name === getRoute('ORDER__CART').name,
    frontSettings: getAppConfigFrontSettings(state),
    upsellingData: getUpsellingData(state),
  }
}

export default compose(
  connect(mapStateToProps, {
    displayDeleteCartDialog,
    goBackToStoreLocator,
    addOrderMenuToCart,
    addOrderProductToCart,
    updateOrderProductOnCart,
    getSuspendedDatas,
    setSuspendedDatas,
    hideSuggestionDialog,
    redirectTo,
    redirectToCatalogPage,
    redirectToCart,
    selectOptionValue,
    unselectOptionValue,
    prepareForFetchingUpsells,
    showDietaryInfoDialog,
    setReclaimLater,
    saveAlcoholApproval,
  }),
  withWidth()
)(CatalogPage)
