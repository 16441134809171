import React from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import isNil from 'lodash/isNil'
import ui from 'pmt-modules/reduxUi'

import { EventManager, SendEventContainer } from 'pmt-modules/event'
import { getRoute, redirectTo } from 'pmt-modules/routing'
import { withAppConfig } from 'pmt-modules/appConfig'
import { withCatalog } from 'pmt-modules/catalog'
import withRestaurant from 'pmt-modules/restaurant/components/withRestaurant'
import { hideSuggestionDialog, SuggestionActionType } from 'pmt-modules/suggestion'
import {
  fromApp,
  getAppConfigFrontSettings,
  goBackToStoreLocator,
  OrderPluginUrlCheckerContainer,
  SelectedCategoryContainer,
  showRestaurantDisabledDialog,
  showRestaurantClosedDialog,
  withCategory,
  withOrderProperties,
} from 'pmt-modules/orderPlugin'
import { prepareForFetchingUpsells } from 'pmt-modules/upselling'
import { displayDeleteCartDialog } from 'pmt-modules/dialog'
import {
  addOrderMenuToCart,
  addOrderProductToCart,
  getItemListFromCart,
  getSuspendedDatas,
  setSuspendedDatas,
} from 'pmt-modules/cart'
import OrderRestaurantContextContainer from 'pmt-modules/orderRestaurantContext/container/OrderRestaurantContextContainer'

import withWidth, { isWidthDown, isWidthUp } from 'pmt-ui/utils/withWidth'
import withScrollToTop from 'pmt-ui/Layout/withScrollToTop'

import OrderPage from '../../components/OrderPage'
import View from './View'
import { getQueryParam } from 'pmt-utils/url'

@withAppConfig
@withOrderProperties
@withRestaurant
@withCatalog
@withCategory
@withScrollToTop({
  isInactive: (prevProps, props) => {
    // we don't want to scroll to top if we change category
    // because we can use the horizontal breadcrumb to go from one subcategory to another
    // or to go from one subcategory to its parent category
    // this horizontal breadcrumb is already quite at the top of the screen
    // and above the first items, so no need to scroll to top to see the first items
    // moreover, for some website with a large header,
    // going at the top of the screen will be bothering because the first items won't be visible due to the header

    // BUT we want to scroll to the top if we go from the parent category to one of its subcategory
    // because this is done by clicking the subcategory in the subcategory list
    // which can be quite long and would need the user to scroll down to see the subcategory name (especially on mobile)
    // and be able to click on it. After the click, we don't want the user to stay "scrolled down", we want them
    // to be at the top of the subcategory items list
    let newCategoryIsChildrenOfPreviousOne = prevProps.category?.categories
      ?.map(c => c.id)
      .includes(props.categoryId)
    return (
      (isNil(prevProps.categoryId) && !isNil(props.categoryId)) ||
      (prevProps.categoryId !== props.categoryId &&
        // we are not coming from the parent category
        !newCategoryIsChildrenOfPreviousOne)
    )
  },
})
class CatalogPageWrapper extends React.Component {
  render() {
    const { restaurant } = this.props
    return (
      <OrderRestaurantContextContainer
        restaurantId={restaurant && restaurant.id}
        withAvailabilities
      >
        {({ orderContext }) => <CatalogPageInner orderContext={orderContext} {...this.props} />}
      </OrderRestaurantContextContainer>
    )
  }
}

class CatalogPageInner extends React.Component {
  constructor(props) {
    super(props)

    this.addSuspendedItem()
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.restaurant && this.props.restaurant && this.props.restaurant.isStatusDisabled) {
      this.props.showRestaurantDisabledDialog()
    }
    if (!prevProps.orderContext && this.props.orderContext && !this.props.orderContext.canOrder) {
      if (getQueryParam('disableRestaurantClosedDialog') !== 'true') {
        this.props.showRestaurantClosedDialog(this.props.orderContext, this.props.restaurant)
      }
    }
  }

  componentWillUnmount() {
    this.props.hideSuggestionDialog()
  }

  addSuspendedItem = () => {
    const {
      cartSuspendedDatas,
      addOrderProductToCart,
      addOrderMenuToCart,
      setSuspendedDatas,
    } = this.props

    // we check if we have suspended item to be added to cart
    // we need to be in replace mode because the compose mode is already added
    if (
      !isNil(cartSuspendedDatas) &&
      cartSuspendedDatas.suspendedActionType === SuggestionActionType.REPLACE
    ) {
      if (cartSuspendedDatas.suspendedItem.isProduct) {
        addOrderProductToCart(cartSuspendedDatas.suspendedItem, { skipSuggestion: true })
      } else if (cartSuspendedDatas.suspendedItem.isMenu) {
        addOrderMenuToCart(cartSuspendedDatas.suspendedItem, { skipSuggestion: true })
      }
      setSuspendedDatas(null, null)
    }
  }

  hasRedirectUriDefined = () => this.props.frontSettings && this.props.frontSettings.redirectUri

  handleRedirectToCart = () => {
    if (!this.props.orderProperties.hasAlreadyDisplayedUpselling) {
      // once the upsells are fetched, if there are some, it will trigger the display of the UpsellingDialog
      this.props.prepareForFetchingUpsells()
    }

    EventManager.dispatch(EventManager.Events.ON_CATALOG_CART_SUBMIT, {
      items: this.props.itemListFromCart,
      restaurant: this.props.restaurant,
    })

    this.props.redirectTo(getRoute('ORDER__CART'))
  }

  handleRedirectToStoreLocatorOrToRedirectUri = () => {
    if (this.hasRedirectUriDefined()) {
      window.location.href = this.props.frontSettings.redirectUri
    } else {
      this.props.itemListFromCart.length > 0
        ? this.props.displayDeleteCartDialog()
        : this.props.goBackToStoreLocator()
    }
  }

  handleRedirectToMobileCatalogTableNumberForm = () => {
    this.props.updateUI({ showTableNumberForm: true })
  }

  render() {
    const {
      appConfigPreset,
      isFetchingCatalog,
      isFetchingRestaurant,
      catalog,
      catalogError,
      orderProperties,
      restaurantError,
      restaurant,
      location,
      params,
      route,
      width,
      ui,
      updateUI,
      itemListFromCart,
    } = this.props

    const enableModifyTableNumber = !orderProperties.disableModifyTableNumber

    return (
      <SelectedCategoryContainer catalog={catalog}>
        {({ selectedCategory, parentCategories, isSubCategory }) => (
          <OrderPage
            headerProps={{
              // hide the header when there is a selected children category, since we display
              // the category header
              displayHeader: isWidthUp('md', width) || !isSubCategory,
              backLink:
                isWidthDown('sm', width) &&
                (orderProperties.mustChooseTableNumber &&
                enableModifyTableNumber &&
                !ui.showTableNumberForm // not already on tableNumberForm
                  ? this.handleRedirectToMobileCatalogTableNumberForm
                  : orderProperties.isOnSite && orderProperties.from !== fromApp.WEBAPP
                  ? // onSite, it doesn't make sense to go to store-locator
                    // (as the user is in a specific store),
                    // so we allow him to "go back" only if coming from the Web customer
                    null
                  : this.handleRedirectToStoreLocatorOrToRedirectUri),
              displayRestaurantName: true,
              displayRestaurantLogo: isWidthUp('sm', width),
              displayTitle: isWidthUp('md', width),
              displayOrderMode: true,
              displayAddress: true,
              changeButton:
                isWidthUp('md', width) && this.handleRedirectToStoreLocatorOrToRedirectUri,
              displayDueDate: isWidthDown('sm', width),
              displayTableNumber: isWidthDown('sm', width),
              displayUser: true,
            }}
            orderProperties={orderProperties}
            restaurant={restaurant}
            location={location}
          >
            <OrderPluginUrlCheckerContainer
              location={location}
              params={params}
              orderProperties={orderProperties}
              checkApiConsumer
              verifyMode
              verifyRestaurantId
              verifyIsAsap
              verifyTableNumber
              verifyFrom
            >
              <SendEventContainer
                send={
                  orderProperties &&
                  !isNil(orderProperties.restaurant) &&
                  !isNil(orderProperties.dueDate)
                }
                event={EventManager.Events.ON_PAGE_LOADED}
                eventProps={() => ({
                  restaurant: orderProperties.restaurant,
                  dueDate: orderProperties.dueDate,
                })}
              >
                <View
                  appConfigPreset={appConfigPreset}
                  isFetchingCatalog={isFetchingCatalog && !catalog}
                  isFetchingRestaurant={isFetchingRestaurant}
                  catalog={catalog}
                  catalogError={catalogError}
                  restaurantError={restaurantError}
                  restaurant={restaurant}
                  orderProperties={orderProperties}
                  hideSideInformations={this.hideSideInformations}
                  showSideInformations={this.showSideInformations}
                  onRedirectToCart={this.handleRedirectToCart}
                  route={route}
                  selectedCategory={selectedCategory}
                  parentCategories={parentCategories}
                  isSubCategory={isSubCategory}
                  handleRedirectToStoreLocator={this.handleRedirectToStoreLocatorOrToRedirectUri}
                  from={orderProperties.from}
                  ui={ui}
                  updateUI={updateUI}
                  enableModifyTableNumber={enableModifyTableNumber}
                  itemListFromCart={itemListFromCart}
                />
              </SendEventContainer>
            </OrderPluginUrlCheckerContainer>
          </OrderPage>
        )}
      </SelectedCategoryContainer>
    )
  }
}

const mapStateToProps = state => ({
  itemListFromCart: getItemListFromCart(state),
  cartSuspendedDatas: getSuspendedDatas(state),
  frontSettings: getAppConfigFrontSettings(state),
})

export default compose(
  connect(mapStateToProps, {
    showRestaurantDisabledDialog,
    showRestaurantClosedDialog,
    displayDeleteCartDialog,
    goBackToStoreLocator,
    addOrderMenuToCart,
    addOrderProductToCart,
    getSuspendedDatas,
    setSuspendedDatas,
    hideSuggestionDialog,
    redirectTo,
    prepareForFetchingUpsells,
  }),
  ui({
    state: {
      showTableNumberForm: false,
    },
  }),
  withWidth()
)(CatalogPageWrapper)
