//
// Note: this file should never be import directly. Use a getter via getters.js
//

import find from 'lodash/find'
import merge from 'lodash/merge'
import isNil from 'lodash/isNil'

const Env = {
  COMMON: {
    VERSION: '0.0.1',

    Logger: {
      SENTRY_URL: 'https://47de7b8f07214aa4b8516ce30a81fbe5@sentry.io/481680',
      DISPLAY_REPORT_DIALOG: true,
    },

    // enable auth feature for this project
    HAS_AUTH_ENABLED: true,
    SHOULD_USE_APP_CONFIG: true,

    IS_ORDER_PLUGIN: true,

    IS_LOCAL: false,
    IS_DEV: false,
    IS_QA: false,
    IS_PREPROD: false,
    IS_PROD: false,

    Google: {
      Map: {
        API_URL: 'https://maps.googleapis.com/maps/api/js',

        API_VERSION: '3.30',

        API_KEY: 'AIzaSyCatd-vZ7uBOKTkfyLJKQ28uszfsmhdTwA',
      },
    },
  },

  LOCAL: {
    ENV: 'DEV',

    HOSTNAME: 'localhost',
    BASE_URL: /localhost/,
    API_URL: 'http://localhost:8080/rest',
    API_CONSUMER: 'Basic NTY0NDIzOTAwNjMzNDk3NjpBT29VeGs5N0JFdTV5Zw==',

    IS_LOCAL: true,
  },

  PREPROD: {
    ENV: 'PREPROD',

    HOSTNAME: 'order2.pluginweb.preprod.paymytable.com',
    BASE_URL: /order2.pluginweb.preprod.paymytable.com/,
    API_URL: 'https://paymytable-eu-preprod.ew.r.appspot.com/rest',
    // API_CONSUMER: 'Basic NjI2NDg0NDc2ODUwOTk1MjpHODl3S3ZYRUJ6MjA',
    API_CONSUMER: 'Basic NDgwMjEzNjgxNDY0OTM0NDpBTXNlcnFEb2FzM3g',

    IS_PREPROD: true,
  },

  PRODUCTION: {
    ENV: 'PROD',

    HOSTNAME: 'order2.pluginweb.paymytable.com',
    BASE_URL: /order2.pluginweb.paymytable.com/,
    API_URL: 'https://order-dot-paymytable-eu.ew.r.appspot.com/rest',
    API_CONSUMER: '',

    IS_PROD: true,
  },
}

let predicate = null

const hostname = window.location.hostname

if (!isNil(window.globalEnvironmentSettings)) {
  predicate = config => window.globalEnvironmentSettings.env === config.ENV
} else {
  predicate = config => (!isNil(config.BASE_URL) ? hostname.match(config.BASE_URL) : null)
}

let currentEnv = find(Env, predicate)

if (process.env.__DEV__) {
  // currentEnv = Env.PREPROD
}

console.log('[CONFIGURATION] ', currentEnv.API_URL, currentEnv.HOSTNAME)

export default merge({}, Env.COMMON, currentEnv)
