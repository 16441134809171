import React from 'react'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import isNil from 'lodash/isNil'

import { haveAuthCredentials } from 'pmt-modules/auth'
import { EventManager, SendEventContainer } from 'pmt-modules/event'
import { withAppConfig } from 'pmt-modules/appConfig'
import withRestaurant from 'pmt-modules/restaurant/components/withRestaurant'
import { withCatalog } from 'pmt-modules/catalog'
import { registerAsIncognito } from 'pmt-modules/registration'
import { redirectTo, getRoute, generatePathWithParams } from 'pmt-modules/routing'
import { displayCart, getItemListFromCart } from 'pmt-modules/cart'
import {
  getAppConfigFrontSettings,
  OrderPluginUrlCheckerContainer,
  getOrderData,
  withOrderProperties,
  resetSelectedCategory,
  selectSlot,
  setParentCategories,
} from 'pmt-modules/orderPlugin'
import { setPaymentMethod } from 'pmt-modules/orderFront'
import { getOpeningHours, PaymentMethodsAllowed } from 'pmt-modules/orderSettings'
import {
  isFetchingOrderPost,
  postOrder,
  resetPostOrder,
  getErrorOrderPost,
} from 'pmt-modules/orderPost'
import { isFetchingUpsells } from 'pmt-modules/upselling'

import withScrollToTop from 'pmt-ui/Layout/withScrollToTop'
import withWidth, { isWidthUp } from 'pmt-ui/utils/withWidth'

import OrderPage from '../../components/OrderPage'
import View from './View'
import { isFetchingRegister } from 'pmt-modules/registration'

/**
 * @specs N/A
 */
@withAppConfig
@withOrderProperties
@withRestaurant
@withCatalog
@withScrollToTop()
class CartPage extends React.PureComponent {
  constructor(props) {
    super(props)

    if (isNil(props.orderProperties.restaurantId)) {
      props.redirectTo(getRoute('ORDER__STORE_LOCATOR'))
    }

    // reset parent categories and selected categories once we reach to cart
    this.props.setParentCategories([])
    this.props.resetSelectedCategory()

    // dispatch a displayCart action in order to trigger an order preview
    this.props.displayCart()

    // init (or reset if already selected) payment method
    // basically we want to be sure not to have any previously payment method set from previous order
    // this is mandatory, don't you dare ever touch it!
    props.setPaymentMethod(null)
  }

  componentWillReceiveProps(nextProps) {
    // we just refreshed to cart page, catalog has not been loaded yet, so we wait for it to be
    // fully loaded and then dispatch a display cart in order to throw an order preview
    if (isNil(this.props.catalog) && !isNil(nextProps.catalog)) {
      nextProps.displayCart()
    }

    // display error only once
    if (nextProps.errorPostOrder) {
      this.previousErrorPostOrder = nextProps.errorPostOrder
      nextProps.resetPostOrder()
    }
  }

  handleCartSubmission = () => {
    // forget about previous order before submitting a new order
    this.props.resetPostOrder()

    EventManager.dispatch(EventManager.Events.ON_CART_SUBMIT, {
      items: this.props.itemListFromCart,
      restaurant: this.props.restaurant,
    })

    /**
     * when skipping payment page, we set payment method as IRL
     * and redirect to delivery address on delivery mode
     * otherwise, we post order directly
     */
    if (this.props.haveAuthCredentials && !this.props.frontSettings.display.paymentPage) {
      this.props.setPaymentMethod(PaymentMethodsAllowed.IRL) // this doesn't change the orderData we use for postOrder, so the API receive paymentMethod=null

      if (this.props.orderProperties.isDelivery) {
        this.props.redirectTo(getRoute('ORDER__DELIVERY_ADDRESS'))
      } else {
        this.props.postOrder(this.props.orderData)
      }
    } else {
      let redirectParams = null

      if (this.props.frontSettings.display.paymentPage) {
        redirectParams = {
          redirectTo: generatePathWithParams(
            this.props.orderProperties.isDelivery
              ? getRoute('ORDER__DELIVERY_ADDRESS')
              : getRoute('ORDER__PAYMENT'),
            { keepQuery: true }
          ),
        }
      }

      // if not displaying paymentPage : we are here because we have haveAuthCredentials=true
      // so we redirect to the login page (without redirectTo param), and the login page will redirect us (because we are already logged in)
      // cf. LoginPage.redirectIfUserIsConnected

      this.props.redirectTo(getRoute('LOGIN'), null, redirectParams)
    }
  }

  render() {
    const {
      appConfigPreset,
      isFetchingOrderPost,
      isFetchingRegister,
      isFetchingUpsells,
      itemListFromCart,
      restaurant,
      isFetchingRestaurant,
      location,
      orderProperties,
      orderAppConfig,
      appConfig,
      width,
    } = this.props

    return (
      <OrderPage
        headerProps={{
          displayTitle: isWidthUp('md', width),
          displayUser: true,
        }}
        orderProperties={orderProperties}
        restaurant={restaurant}
        location={location}
      >
        <OrderPluginUrlCheckerContainer location={location} verifyMode>
          <SendEventContainer
            send={orderProperties && !isNil(orderProperties.restaurant)}
            event={EventManager.Events.ON_PAGE_LOADED}
            eventProps={() => ({
              items: itemListFromCart,
              restaurant: orderProperties.restaurant,
              dueDate: orderProperties.dueDate,
            })}
          >
            <View
              appConfigPreset={appConfigPreset}
              isFetching={
                isFetchingOrderPost ||
                isFetchingRegister ||
                isFetchingRestaurant ||
                isFetchingUpsells
              }
              orderProperties={orderProperties}
              restaurant={restaurant}
              handleCartSubmission={this.handleCartSubmission}
              orderAppConfig={orderAppConfig}
              appConfig={appConfig}
              route={this.props.route}
              errorPostOrder={this.previousErrorPostOrder}
            />
          </SendEventContainer>
        </OrderPluginUrlCheckerContainer>
      </OrderPage>
    )
  }
}

const mapStateToProps = (state, props) => ({
  haveAuthCredentials: haveAuthCredentials(state),
  frontSettings: getAppConfigFrontSettings(state),
  itemListFromCart: getItemListFromCart(state),
  openingHours: getOpeningHours(state),
  isFetchingOrderPost: isFetchingOrderPost(state),
  isFetchingRegister: isFetchingRegister(state),
  isFetchingUpsells: isFetchingUpsells(state),
  orderData: getOrderData(state),
  errorPostOrder: getErrorOrderPost(state),
})

export default compose(
  connect(mapStateToProps, {
    displayCart,
    redirectTo,
    resetSelectedCategory,
    resetPostOrder,
    postOrder,
    setPaymentMethod,
    selectSlot,
    setParentCategories,
    registerAsIncognito,
  }),
  withWidth()
)(CartPage)
