import { tr } from 'pmt-modules/i18n'
import classNames from 'classnames'
import get from 'lodash/get'
import isNil from 'lodash/isNil'
import React from 'react'
import { connect } from 'react-redux'

import { EventManager } from 'pmt-modules/event'
import { getAppConfigFrontSettings } from 'pmt-modules/orderPlugin'
import { getOrderPreview, getOrderPreviewVerifications } from 'pmt-modules/orderPreview'

import Card, { CardContent } from 'pmt-ui/Card'
import LoadingBlock from 'pmt-ui/LoadingBlock'
import ActionCheckCircle from 'pmt-ui/svg-icons/action/check-circle'
import ActionInfoOutline from 'pmt-ui/svg-icons/action/info-outline'
import ToggleContainer from 'pmt-ui/ToggleContainer'
import { TypographyCustom } from 'pmt-ui/Typography'

import { formatPriceWithCurrency } from 'pmt-utils/currency'

import { ButtonLink } from '../../../components/Button'
import { CartFooter, CartItemList } from '../../../components/Cart'

const PaymentDatasCard = ({
  appConfigPreset,
  orderProperties,
  frontSettings,
  cartItems,
  cartData,
  orderData,
  orderPreview,
  restaurant,
  orderPreviewVerifications,
  route,
  classes,
}) => (
  <Card className={classNames('u-marginBottom10', classes.maxWidthContainer)}>
    <CardContent className={classes.cardContent}>
      {frontSettings.display.totalPrices && (
        <React.Fragment>
          <TypographyCustom type="164" component="p" className="u-marginBottom15">
            {tr('order.confirm.total')}
            <TypographyCustom type="164" component="span" className="u-floatRight">
              {formatPriceWithCurrency(
                // totalPrice contains the admission fees amount
                orderData.totalPrice -
                  get(orderPreviewVerifications, 'userAccount.subsidies.grantAmount', 0)
              )}
            </TypographyCustom>
          </TypographyCustom>

          {orderData.tips > 0 && (
            <TypographyCustom type="164" component="p" className="u-marginBottom15">
              {tr('order.confirm.including_tips')}
              <TypographyCustom type="164" component="span" className="u-floatRight">
                {formatPriceWithCurrency(orderData.tips)}
              </TypographyCustom>
            </TypographyCustom>
          )}
        </React.Fragment>
      )}

      {orderData.totalPrice > 0 && (
        <React.Fragment>
          {orderProperties.payment.isCreditCardMethod ||
          orderProperties.payment.isUserAccountMethod ? (
            <div className="u-overflowHidden">
              <ActionCheckCircle className={classNames(classes.icon, classes.iconGreen)} />
              <TypographyCustom type="147" component="p" className={classes.iconText}>
                {tr(`order.confirm.already_paid.${orderProperties.paymentMethod}`)}
              </TypographyCustom>
            </div>
          ) : (
            orderProperties.payment.isIrlMethod &&
            (frontSettings.display.totalPrices ? (
              <div className="u-overflowHidden">
                <ActionInfoOutline className={classNames(classes.icon, classes.iconWarning)} />
                <TypographyCustom type="147" component="p" className={classes.iconText}>
                  {tr(`order.confirm.left_to_pay.${orderProperties.mode}`, {
                    amount: orderData.totalPriceFormatted,
                  })}
                </TypographyCustom>
              </div>
            ) : (
              <div className="u-overflowHidden">
                <ActionInfoOutline className={classNames(classes.icon, classes.iconWarning)} />
                <TypographyCustom type="147" component="p" className={classes.iconText}>
                  {tr(`order.confirm.total_to_pay.${orderProperties.mode}`)}
                </TypographyCustom>
              </div>
            ))
          )}
          <hr className={classes.hr} />
        </React.Fragment>
      )}

      <ToggleContainer defaultOpen={false}>
        {({ isOpen, toggle }) =>
          isOpen ? (
            <React.Fragment>
              {!isNil(cartItems) ? (
                <React.Fragment>
                  <CartItemList
                    enabledClickableItems={false}
                    enabledQuantityChange={false}
                    fromRoute={route}
                    itemList={cartItems}
                    type={CartItemList.Type.SMALL}
                    appConfigPreset={appConfigPreset}
                    orderModifiers={orderData.modifiers}
                  />
                  <CartFooter
                    comment={cartData.comment}
                    displayMinimumPricesForFree={false}
                    enabledSubmitCart={false}
                    fees={orderData.fees}
                    itemList={cartItems}
                    minimumPrice={cartData.minimumPrice}
                    minimumPriceFormatted={cartData.minimumPriceFormatted}
                    restaurant={restaurant}
                    orderProperties={orderProperties}
                    totalCartPrice={cartData.totalCartPrice}
                    totalCartPriceFormatted={cartData.totalCartPriceFormatted}
                    // total including taxes + display of tax amount (if provided by the API, aka for USA only)
                    totalCartPriceAndFeesFormatted={orderProperties.totalCartPriceAndFeesFormatted}
                    totalTaxAmount={orderProperties.totalTaxAmount}
                    totalTaxAmountFormatted={orderProperties.totalTaxAmountFormatted}
                    type={CartFooter.Type.SMALL}
                    orderModifiers={orderData.modifiers}
                  />
                </React.Fragment>
              ) : (
                <LoadingBlock
                  show
                  classes={{
                    loadingBlock: classNames('u-marginTop20', classes.loadingBlock),
                    circularProgress: classes.progress,
                  }}
                />
              )}
            </React.Fragment>
          ) : (
            <div className="u-textAlignCenter">
              <ButtonLink
                classes={{
                  root: classNames(classes.mainColor, classes.cursorPointer),
                }}
                disabled={isNil(orderProperties.mode)}
                onClick={() => {
                  EventManager.dispatch(EventManager.Events.ON_CONFIRM_ORDER_DETAIL)
                  toggle()
                }}
                label={tr('order.confirm.order_detail')}
              />
            </div>
          )
        }
      </ToggleContainer>
    </CardContent>
  </Card>
)

const mapStateToProps = (state, props) => ({
  frontSettings: getAppConfigFrontSettings(state),
  orderPreview: getOrderPreview(state),
  orderPreviewVerifications: getOrderPreviewVerifications(state),
})

export default connect(mapStateToProps, {})(PaymentDatasCard)
