import { tr } from 'pmt-modules/i18n'
import React from 'react'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import classNames from 'classnames'
import values from 'lodash/values'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'

import { EventManager } from 'pmt-modules/event'
import {
  addOrderMenuToCart,
  addOrderProductToCart,
  addToCartFromShortcut,
  setSuspendedDatas,
} from 'pmt-modules/cart'
import { getOrderProperties } from 'pmt-modules/orderPlugin'
import { getSuggestion, hideSuggestionDialog, SuggestionActionType } from 'pmt-modules/suggestion'
import { openProduct } from 'pmt-modules/orderProduct'
import {
  createOrderMenuFromMenu,
  getFilledMenu,
  isMenuAutofillable,
  openMenu,
} from 'pmt-modules/orderMenu'
import {
  withOrderProperties,
  saveAlcoholApproval,
  getAppConfigFrontSettings,
} from 'pmt-modules/orderPlugin'
import withRestaurant from 'pmt-modules/restaurant/components/withRestaurant'
import AlertButton from 'pmt-ui/AlertButton'
import CustomTextsContainer from 'pmt-modules/customTexts/components/CustomTextsContainer'
import { CardItem } from 'pmt-ui/Card'
import { CardItemOrder } from 'pmt-ui/Card/components/CardItem'
import { DialogActions, DialogContent, DialogTitle } from 'pmt-ui/Dialog'
import { TypographyCustom } from 'pmt-ui/Typography'
import withWidth, { isWidthDown } from 'pmt-ui/utils/withWidth'
import { withStyles } from 'pmt-ui/styles'
import ActionDone from 'pmt-ui/svg-icons/action/done'
import ContentAdd from 'pmt-ui/svg-icons/content/add'
import Grid from 'pmt-ui/Grid'
import StateMachine from 'pmt-ui/StateMachine'
import CloseIcon from 'pmt-ui/svg-icons/navigation/close'

import Button from '../../../components/Button'
import Chip from '../../../components/Chip'
import CartModifierTag from '../../../components/CartModifier/CartModifierTag'
import { OrderItemTrackingType } from 'pmt-modules/order'

const styles = theme => ({
  dialogTitle: {
    float: 'left',
    width: 'calc(100% - 35px)',
    color: theme.palette.primary.main,
  },
  closeArea: {
    float: 'right',
    cursor: 'pointer',
  },
  closeBtn: {
    width: 35,
    height: 35,
  },
  dialogActions: {
    cursor: 'pointer',
    background: theme.pmt.colors.greyBackground,
    boxShadow: `0 0 4px 0 ${theme.pmt.colors.darkGrey}`,
    justifyContent: 'center',
    margin: 0,
    padding: `${theme.spacing(2)}px 0`,
    zIndex: 1,
  },
  actionTypeContainer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
    },
    '&:last-child': {
      marginBottom: 0,
    },
  },
  actionTypeLabelWrapper: {
    [theme.breakpoints.up('md')]: {
      ...theme.pmt.mixins.textEllipsis,
    },
  },
  actionTypeLabel: {
    fontWeight: 700,
  },
  orderItemName: {
    [theme.breakpoints.up('md')]: {
      ...theme.pmt.mixins.textEllipsis,
      minHeight: 19,
    },
    // on mobile device, bring the product name on the same line than action type label
    // and display a "space" before
    [theme.breakpoints.down('sm')]: {
      display: 'inline',
      '&:before': {
        content: '" "',
      },
    },
  },
  title: {
    color: theme.palette.primary.main,
  },
  cartModifierChip: {
    display: 'table',
    marginBottom: theme.spacing(0.5),
    cursor: 'pointer',
    borderRadius: 4,
  },
  cartModifierUnavailable: {
    opacity: 0.2,
  },
})

const State = {
  NORMAL: 'NORMAL',
  ADDING_TO_CART: 'ADDING_TO_CART',
}

/**
 * @specs N/A
 */
@withRestaurant
@withOrderProperties
class SuggestionDialog extends React.PureComponent {
  getItemSuggestedPrice(item, actionType) {
    let price = ''

    if (item.suggestedPrice === 0 && actionType === SuggestionActionType.REPLACE) {
      price = tr('global.suggestions.same_price')
      return price
    }

    if (actionType === SuggestionActionType.REPLACE) {
      price = `${item.suggestedPrice > 0 ? '+' : ''}`
    }

    price += item.suggestedPriceFormatted

    return price
  }

  handleOpenItem = (item, actionType, options) => {
    const {
      addOrderMenuToCart,
      setSuspendedDatas,
      hideSuggestionDialog,
      openMenu,
      openProduct,
    } = this.props

    // we set suspended item here so we can add it if the user cancel on product/menu view
    setSuspendedDatas(this.props.orderItem, actionType)
    hideSuggestionDialog()

    if (item.isProduct) {
      openProduct(item, options)
    } else if (item.isMenu) {
      if (actionType === SuggestionActionType.REPLACE && isMenuAutofillable(item)) {
        const filledMenu =
          item.isOrderMenu === true
            ? getFilledMenu(item)
            : getFilledMenu(createOrderMenuFromMenu(item))

        addOrderMenuToCart(filledMenu, { skipSuggestion: true })
        setSuspendedDatas(null, null)
      } else {
        openMenu(item, options)
      }
    }
  }

  handleHideDialogAndAddItem = () => {
    this.props.hideSuggestionDialog()

    if (this.props.orderItem.isProduct) {
      EventManager.dispatch(EventManager.Events.ON_CATALOG_ITEM_ADD_PRODUCT, {
        dueDate: this.props.orderProperties.dueDate,
        item: this.props.orderItem,
        restaurant: this.props.restaurant,
      })
      this.props.addOrderProductToCart(this.props.orderItem, { skipSuggestion: true })
    } else if (this.props.orderItem.isMenu) {
      EventManager.dispatch(EventManager.Events.ON_CATALOG_ITEM_ADD_MENU, {
        dueDate: this.props.orderProperties.dueDate,
        item: this.props.orderItem,
        restaurant: this.props.restaurant,
      })
      this.props.addOrderMenuToCart(this.props.orderItem, { skipSuggestion: true })
    }
  }

  getItemsByActionType = (suggestions, actionType) =>
    suggestions.filter(({ item, action }) => action === actionType && item.available)

  addTracking = items => {
    items.forEach(
      item => (item.item.tracking = OrderItemTrackingType.ADDED_BY_MANUALLY_CONFIGURED_SUGGESTIONS)
    )
  }

  processAddBtnClick = (item, actionType, restaurant, transitionTo, addToCartFromShortcut) => {
    if (item.available) {
      // handle the item triggering the suggestion
      // compose = we put it on the cart (the suggested item will - or not - be added later)
      // replace = we don't put it on the cart
      if (actionType === SuggestionActionType.COMPOSE) {
        this.handleHideDialogAndAddItem()
      } else {
        this.props.hideSuggestionDialog()
      }

      // handle the suggested item
      if (item.isComplexItem) {
        this.handleOpenItem(item, actionType, {
          restaurantId: restaurant.id,
          skipSuggestion: true,
        })
      } else {
        transitionTo(State.ADDING_TO_CART)
        EventManager.dispatch(EventManager.Events.ON_CATALOG_ITEM_ADD_PRODUCT, {
          item,
          restaurant,
        })
        addToCartFromShortcut(item)
      }
    }
  }

  render() {
    const {
      classes,
      orderItem,
      addToCartFromShortcut,
      restaurant,
      width,
      orderProperties,
      saveAlcoholApproval,
      frontSettings,
    } = this.props
    const isMobile = isWidthDown('sm', width)

    return (
      <React.Fragment>
        <DialogTitle disableTypography>
          <TypographyCustom type="287" skipColor className={classes.dialogTitle}>
            {!isNil(orderItem.suggestedIntroduction) && !isEmpty(orderItem.suggestedIntroduction)
              ? orderItem.suggestedIntroduction
              : tr('order.suggestions')}
          </TypographyCustom>
          <div className={classes.closeArea} onClick={this.handleHideDialogAndAddItem}>
            <CloseIcon className={classes.closeBtn} />
          </div>
        </DialogTitle>
        <DialogContent>
          {values(SuggestionActionType).map(actionType => {
            const items = this.getItemsByActionType(orderItem.suggestions, actionType)
            this.addTracking(items)

            return (
              items.length > 0 && (
                <Grid
                  spacing={2}
                  key={`actionType-${actionType}-label`}
                  container
                  className={classes.actionTypeContainer}
                >
                  {SuggestionActionType.COMPOSE !== actionType && (
                    <Grid item xs={12} md={3} className={classes.actionTypeLabelWrapper}>
                      <TypographyCustom component="span" type={204}>
                        <React.Fragment>
                          <span className={classes.actionTypeLabel}>
                            {tr(`order.suggestions.action_type.${actionType}`)}
                          </span>
                          &nbsp;
                          {tr(`order.suggestions.action_type.your`)}
                        </React.Fragment>
                      </TypographyCustom>
                      <TypographyCustom className={classes.orderItemName} type={204}>
                        {orderItem.name}
                      </TypographyCustom>
                    </Grid>
                  )}
                  <Grid
                    spacing={isMobile ? 0 : 2}
                    container
                    key={`actionType-${actionType}-items`}
                    item
                    xs={12}
                    md={SuggestionActionType.COMPOSE !== actionType ? 9 : 12}
                  >
                    {items.map(({ item, action }) => (
                      <Grid
                        key={`item-${item.id}`}
                        item
                        xs={12}
                        md={SuggestionActionType.COMPOSE !== actionType ? 6 : 4}
                        className={isMobile ? 'u-marginBottom20' : null}
                      >
                        <StateMachine
                          states={State}
                          default={State.NORMAL}
                          transitions={[
                            {
                              state: State.ADDING_TO_CART,
                              to: State.NORMAL,
                              duration: 1000,
                            },
                          ]}
                        >
                          {({ currentState, transitionTo }) => {
                            const addBtnIcon =
                              actionType === SuggestionActionType.COMPOSE &&
                              (currentState === State.NORMAL ? <ContentAdd /> : <ActionDone />)
                            const addBtnLabel = tr(
                              actionType === SuggestionActionType.COMPOSE
                                ? currentState === State.NORMAL
                                  ? 'order.catalog.add'
                                  : 'order.catalog.added'
                                : currentState === State.NORMAL
                                ? 'order.catalog.replace'
                                : 'order.catalog.replaced'
                            )

                            return (
                              <CardItem
                                type={<CardItemOrder />}
                                classes={{
                                  title: classes.title,
                                }}
                                // give item even if it is not used, to simplify debug on react dev tools
                                item={item}
                                description={item.description}
                                image={item.image}
                                restaurantLogo={restaurant.logo}
                                name={item.name}
                                price={this.getItemSuggestedPrice(item, actionType)}
                                isAvailable={item.available}
                                isCategory={item.isCategory}
                                hasCartModifier={
                                  SuggestionActionType.REPLACE !== actionType &&
                                  item.hasCartModifier
                                }
                                basePrice={
                                  SuggestionActionType.REPLACE !== actionType &&
                                  item.hasCartModifier
                                    ? item.basePriceFormatted
                                    : null
                                }
                                cartModifierTag={(cartModifier, index) => (
                                  <CartModifierTag
                                    cartModifier={cartModifier}
                                    chipElement={
                                      <Chip
                                        key={index}
                                        label={cartModifier.tag}
                                        noBorder
                                        classes={{
                                          root: classNames(classes.cartModifierChip, {
                                            [classes.cartModifierUnavailable]: !item.available,
                                          }),
                                        }}
                                      />
                                    }
                                    key={index}
                                    overEventsEnabled={false}
                                  />
                                )}
                                onClickCard={() => {
                                  if (item.available) {
                                    EventManager.dispatch(
                                      EventManager.Events.ON_CATALOG_ITEM_DETAIL,
                                      {
                                        item,
                                        restaurant,
                                        category: {
                                          name: 'Suggestion',
                                        },
                                      }
                                    )

                                    if (actionType === SuggestionActionType.COMPOSE) {
                                      this.handleHideDialogAndAddItem()
                                    }
                                    this.handleOpenItem(item, actionType, {
                                      restaurantId: restaurant.id,
                                      skipSuggestion: true,
                                    })
                                  }
                                }}
                                buttonAdd={
                                  frontSettings.display.ageCheckForAlcohol &&
                                  item.isProduct &&
                                  !item.isComplexItem &&
                                  item.containsAlcohol &&
                                  !orderProperties.alcoholApprovalAnswer ? (
                                    <CustomTextsContainer>
                                      {({ texts }) => (
                                        <AlertButton
                                          button={<Button />}
                                          variant="outlined"
                                          size="small"
                                          icon={addBtnIcon}
                                          label={addBtnLabel}
                                          onAgree={e => {
                                            e.stopPropagation()
                                            saveAlcoholApproval()
                                            this.processAddBtnClick(
                                              item,
                                              actionType,
                                              restaurant,
                                              transitionTo,
                                              addToCartFromShortcut
                                            )
                                          }}
                                          alertTitle={
                                            !isNil(texts) &&
                                            !isNil(
                                              texts.ORDER__CART__AGE_CHECK_FOR_ALCOHOL__DIALOG_TITLE
                                            )
                                              ? texts.ORDER__CART__AGE_CHECK_FOR_ALCOHOL__DIALOG_TITLE
                                              : tr('order.cart.age_check_for_alcohol.dialog.title')
                                          }
                                          alertContent={
                                            !isNil(texts) &&
                                            !isNil(
                                              texts.ORDER__CART__AGE_CHECK_FOR_ALCOHOL__DIALOG_DESCRIPTION
                                            )
                                              ? texts.ORDER__CART__AGE_CHECK_FOR_ALCOHOL__DIALOG_DESCRIPTION
                                              : tr(
                                                  'order.cart.age_check_for_alcohol.dialog.description'
                                                )
                                          }
                                          okLabel={
                                            !isNil(texts) &&
                                            !isNil(
                                              texts.ORDER__CART__AGE_CHECK_FOR_ALCOHOL__DIALOG_OK
                                            )
                                              ? texts.ORDER__CART__AGE_CHECK_FOR_ALCOHOL__DIALOG_OK
                                              : tr('order.cart.age_check_for_alcohol.dialog.ok')
                                          }
                                          cancelLabel={
                                            !isNil(texts) &&
                                            !isNil(
                                              texts.ORDER__CART__AGE_CHECK_FOR_ALCOHOL__DIALOG_CANCEL
                                            )
                                              ? texts.ORDER__CART__AGE_CHECK_FOR_ALCOHOL__DIALOG_CANCEL
                                              : tr('order.cart.age_check_for_alcohol.dialog.cancel')
                                          }
                                        />
                                      )}
                                    </CustomTextsContainer>
                                  ) : (
                                    <Button
                                      variant="outlined"
                                      size="small"
                                      icon={addBtnIcon}
                                      label={addBtnLabel}
                                      onClick={e => {
                                        e.stopPropagation()
                                        this.processAddBtnClick(
                                          item,
                                          actionType,
                                          restaurant,
                                          transitionTo,
                                          addToCartFromShortcut
                                        )
                                      }}
                                    />
                                  )
                                }
                              />
                            )
                          }}
                        </StateMachine>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              )
            )
          })}
        </DialogContent>
        <DialogActions classes={{ root: classes.dialogActions }}>
          <Button
            variant="outlined"
            label={tr('order.suggestions.no_thanks')}
            onClick={this.handleHideDialogAndAddItem}
          />
        </DialogActions>
      </React.Fragment>
    )
  }
}

// Dialog root properties
SuggestionDialog.DialogRootProps = {
  // we want the dialog to quit when we click on the backdrop
  disableBackdropClick: true,
  disableEscapeKeyDown: true,
}

SuggestionDialog.DialogStyles = theme => ({
  paper: {
    // fix weird bug on Safari
    zIndex: 100,
    // -- for IE
    '-ms-overflow-style': '-ms-autohiding-scrollbar',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '100%',
      maxHeight: '100%',
      margin: 0,
      borderRadius: 0,
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: 1100,
      // -- for IE
      width: '100%',
    },
  },
})

const mapStateToProps = (state, props) => ({
  orderProperties: getOrderProperties(state),
  orderItem: getSuggestion(state, props),
  frontSettings: getAppConfigFrontSettings(state),
})

export default compose(
  withWidth(),
  withStyles(styles),
  connect(mapStateToProps, {
    addOrderProductToCart,
    addOrderMenuToCart,
    setSuspendedDatas,
    hideSuggestionDialog,
    addToCartFromShortcut,
    openMenu,
    openProduct,
    saveAlcoholApproval,
  })
)(SuggestionDialog)
